import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Chips, Switch } from '@navikt/ds-react'

import HomeFoodItem from '../components/HomeFoodItem'
import RecipeTags from '../components/RecipeTags'
import SelectableFoodList from '../components/SelectableFoodList'
import SelectHomeFood from '../components/HomeFoodInput'
import { findRandomRecipe } from '../util/api'
import { loadHomeIngridients, saveHomeIngridients, loadFoodCategories, saveFoodCategories, DEFAULT_FOOD_CATEGORIES } from '../util/storage'

import './SelectionFilter.css'

export default function SelectionFilter () {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [foodCategories, setFoodCategories] = useState(loadFoodCategories())
    const [homeIngridients, setHomeIngridients] = useState(loadHomeIngridients())
    const [filterWithAll, setFilterWithAll] = useState(false)
    const [tags, setTags] = useState([])
    const [foodInputFilter, setFoodInputFilter] = useState('')
    const [homeFood, setHomeFood] = useState([]);

    const setFoodCategoriesWithSave = (categories) => {
        saveFoodCategories(categories)
        setFoodCategories(categories)
    }

    const findRecipe = () => {
        const types = foodCategories

        const recipe = findRandomRecipe(filterWithAll ? [] : homeIngridients, types)
        console.log(recipe)
        navigate(`/recipe-view/${recipe.id}`)
    }

    const updateFoodCategories = (category) => {
        if (foodCategories.includes(category)) {
            // need to remove
            setFoodCategoriesWithSave(foodCategories.filter((x) => x !== category))
        } else {
            // need to add
            setFoodCategoriesWithSave([...foodCategories, category])
        }
    }

    const updateHomeFoodWithSelection = (food) => {
        setFoodInputFilter('')
        const foodList = [].concat(homeFood, [food])
        setHomeFood(foodList)
        saveHomeIngredients(foodList)
    }

    const removeFromHomeFood = (food) => {
        const foodList = homeFood.filter(f => f.id !== food.id)
        setHomeFood(foodList)
        saveHomeIngredients(foodList)
    }

    return (
        <div className="recipe-filter" style={{ width: '100%' }}>
            <div className='page-title'>{t('app.screen.filter-food-selection')}</div>
            <div className="full-width food-filter-categories">
                <Chips>
                    {DEFAULT_FOOD_CATEGORIES.map((foodCategory) =>
                        <Chips.Toggle key={foodCategory} selected={foodCategories?.includes(foodCategory)} onClick={() => updateFoodCategories(foodCategory)}>
                            {t(foodCategory)}
                        </Chips.Toggle>
                    )}
                </Chips>
            </div>

            <div>
                <RecipeTags tags={tags} setTags={setTags} />
            </div>

            <div className="enter-food">
                <label>{t('app.screen.filter-food-selection.ingredients-to-use')}:</label>
                <div className="food-at-home">
                {homeFood.map((ingredient, index) => 
                    <HomeFoodItem ingredient={ingredient} key={`${ingredient.id}-${index}`} onClick={removeFromHomeFood} />
                )}
                </div>
                <SelectHomeFood onFilter={setFoodInputFilter} inputValue={foodInputFilter} />
                <div >
                    <SelectableFoodList 
                        foodInputFilter={foodInputFilter} 
                        selectedFood={homeFood} 
                        onFoodSelect={updateHomeFoodWithSelection} 
                        useFoodInputFilter={false}
                    />
                </div>
            </div>

            <div className="find-recipes">
                <button className="button-language" onClick={() => findRecipe()}>{t('Select')}</button>
            </div>
        </div>
    )
}