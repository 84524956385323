import '@navikt/ds-css'
import { useTranslation } from "react-i18next"
import { useEffect, useState } from 'react'
import { usePWAInstall } from 'react-use-pwa-install'

import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import { subscribe, unsubscribe } from "./events";

import { Routes, Route, Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom'

import Language from './screen/Language'
import Home from './screen/Home'
import RecipeView from './screen/RecipeView'
import RecipeFilter from './screen/RecipeFilter'
import RecipesAlternativesView from './screen/RecipeAlternativesView'

import DaysListView from './screen/DaysListView'
import FavoriteListView from './screen/FavoriteListView'
import ShoppingList from './screen/ShoppingList'
import FoodAtHome from './screen/FoodAtHome'
import Profile from './screen/Profile'
import SelectionFilter from './screen/SelectionFilter'
import Debug from './screen/Debug'

import { setUserLanguage } from "./util/usersettings"

import { APP_VERSION } from './config'

import './util/i18n'
import './App.css'

import backPng from './images/back.png'
import logo from './images/logo.png'

import homeIcon from './images/home-24-24.png'
import foodAtHomeIcon from './images/at-home-24-24.png'
import shoppingListIcon from './images/shopping-list-24-24.png'
import profileIcon from './images/account-24-24.png'
import loadingIcon2 from './images/loading.svg'

const BackButton = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }
  return (
    <div onClick={goBack}>
      <img height="16px" src={backPng} alt={t('Back')} />
    </div>
  )
}

export const AppBottomMenu = () => {
  const { t } = useTranslation()
  return <div className='app-bottom-menu'>
    <div>
      <div className='app-bottom-menu-icon'><Link to={'/home'}><img src={homeIcon} /></Link></div>
      <div className='app-bottom-menu-title'><Link to={'/home'}>{t('appmenu.home')}</Link></div>
    </div>
    <div>
      <div className='app-bottom-menu-icon'><Link to={'/food-at-home'}><img src={foodAtHomeIcon} /></Link></div>
      <div className='app-bottom-menu-title'><Link to={'/food-at-home'}>{t('appmenu.food-at-home')}</Link></div>
    </div>
    <div>
      <div className='app-bottom-menu-icon'><Link to={'/shopping-list'}><img src={shoppingListIcon} /></Link></div>
      <div className='app-bottom-menu-title'><Link to={'/shopping-list'} style={{ textDecoration: 'none' }}>{t('appmenu.shopping-list')}</Link></div>
    </div>
    <div>
      <div className='app-bottom-menu-icon'><Link to={'/profile'}><img src={profileIcon} /></Link></div>
      <div className='app-bottom-menu-title'><Link to={'/profile'}>{t('appmenu.profile')}</Link></div>
    </div>
  </div>
}

export const AppDesktopMenu = () => {
  const { t } = useTranslation()
  return (
  <div className='app-desktop-menu'>
    <div className='app-logo'>
        <div>
            <img style={{maxWidth: '200px'}} src={logo}  />
        </div>
    </div>
    <div style={{flexGrow: '2'}}></div>
    <div className='app-desktop-menu-items'>
      <div>
        <div className='app-desktop-menu-icon'><Link to={'/home'}><img src={homeIcon} /></Link></div>
        <div className='app-desktop-menu-title'><Link to={'/home'}>{t('appmenu.home')}</Link></div>
      </div>
      <div>
        <div className='app-desktop-menu-icon'><Link to={'/food-at-home'}><img src={foodAtHomeIcon} /></Link></div>
        <div className='app-desktop-menu-title'><Link to={'/food-at-home'}>{t('appmenu.food-at-home')}</Link></div>
      </div>
      <div>
        <div className='app-desktop-menu-icon'><Link to={'/shopping-list'}><img src={shoppingListIcon} /></Link></div>
        <div className='app-desktop-menu-title'><Link to={'/shopping-list'} style={{ textDecoration: 'none' }}>{t('appmenu.shopping-list')}</Link></div>
      </div>
      <div>
        <div className='app-desktop-menu-icon'><Link to={'/profile'}><img src={profileIcon} /></Link></div>
        <div className='app-desktop-menu-title'><Link to={'/profile'}>{t('appmenu.profile')}</Link></div>
      </div>
    </div>
  </div>)
}

const InstallApp = () => {
  const install = usePWAInstall()
  if (!install) {
    return null
  }

  return <div className="install">
    <button onClick={install}>Install app</button>
  </div>
}

function App () {
  const location = useLocation()
  const { i18n } = useTranslation();
  const [showBackButton, setShowBackButton] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if ((window.location.pathname === '/home') || (window.location.pathname === '/main')) {
      setShowBackButton(false)
    } else {
      setShowBackButton(true)
    }
  }, [location.pathname])

  const [searchParams] = useSearchParams();

  useEffect( () => {
    if (searchParams.get('language')) {
      const setLanguage = (lang) => {
        setUserLanguage(lang)
        i18n.changeLanguage(lang);
      }
      setLanguage(searchParams.get('language'))
    }

    subscribe("showLoading", () => setLoading(true));
    subscribe("hideLoading", () => setLoading(false));

    return () => {
      unsubscribe("showLoading", () => setLoading(true));
      unsubscribe("hideLoading", () => setLoading(false));
    }
  }, [])

  return (
    <div className="app">
      <InstallApp />
      {loading && 
        <div className='app-loading'>
          <div className='app-loading-content'>
            <img src={loadingIcon2} />
          </div>
        </div>
      }
      <AppDesktopMenu />
      <div className="main-block">
        <Routes>
          <Route path="/" element={<Language />} />
          <Route path="/filter" element={<RecipeFilter />} />
          <Route path="/language" element={<Language />} />
          <Route path="/main" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/selection" element={<SelectionFilter />} />
          <Route path="/recipe-view/:id" element={<RecipeView />} />
          <Route path="/recipe-alternatives" element={<RecipesAlternativesView />} />
          <Route path="/recipe-alternatives/:id" element={<RecipesAlternativesView />} />
       
          
          <Route path="/week-recipies" element={<DaysListView />} />
          <Route path="/favorite-recipies" element={<FavoriteListView />} />
          <Route path="/shopping-list" element={<ShoppingList />} />
          <Route path="/food-at-home" element={<FoodAtHome />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/debug" element={<Debug />} />
        </Routes>
        <div className="app-version">v{APP_VERSION}</div>
      </div>
      <AppBottomMenu />
    </div>
  )
}

export default App

